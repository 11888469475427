import {
  Annuity,
  Contactless,
  Discount,
  LegalTerms,
  MinimumIncome,
  Partners,
  Pointing,
} from "@mkt/ui/icons/Ccbr/png"
import SbEditable from "@mkt/ui/components/common/SbEditable"
import { render } from "storyblok-rich-text-react-renderer"
import Img from "@mkt/ui/components/common/Img"
import Condition from "@mkt/ui/components/common/Condition"

import * as S from "./styles"

const mapImage = {
  OutlineMoney: MinimumIncome,
  OutlineAnnuity: Annuity,
  OutlineCreditCardPoints: Pointing,
  OutlineGift: Pointing,
  OutlineDiscounts: Discount,
  OutlinePartnership: Partners,
  OutlineContactless: Contactless,
  OutlineLegalInformation: LegalTerms,
}

const CardDetails = ({ content }) => {

  const blok = content?.product_benefits[0]
  const {disclaimer} = content

  return (
    <SbEditable content={blok}>
      <div css={S.CardDetails}>
        <div className="card-details__content">
          <div className="card-details__detail">
            <h2 css={S.CardDetailsTitle}>{blok?.title}</h2>
            <div css={S.CardDetailsBullets}>
              {blok?.body.map((benefit, index) => (
                <div
                  css={S.CardDetailsBullet}
                  key={`card-details-bullet-${index}`}
                >
                  <div css={S.CardDetailsBulletIcon}>
                    <Img
                      src={mapImage[benefit?.icon]}
                      width="72px"
                      height="72px"
                      alt="benefit icon"
                    />
                  </div>
                  <div css={S.CardDetailsBulletText}>
                    <span className="bullet-info">
                      <span>{render(benefit?.title)}</span>
                      {render(benefit?.desc)}
                    </span>
                  </div>
                </div>
              ))}{" "}
                <Condition condition={disclaimer}>
                  <div css={S.disclaimer}>{render(disclaimer)}</div>
                </Condition>
            </div>
          </div>
        </div>
      </div>
    </SbEditable>
  )
}

export default CardDetails
